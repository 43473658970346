document?.addEventListener('turbolinks:load', function() {
  document.querySelectorAll('.btn-toggle-state').forEach(button => {
    button.addEventListener('click', function(event) {
      event.preventDefault();

      debugger;
      document.querySelectorAll('.btn-toggle-state').forEach(btn => btn.classList.remove('active'));

      const radioButton = document.getElementById(this.dataset.for);
      radioButton.checked = true;
      this.classList.add('active');

      const existingMessage = document.querySelector('.state-message');
      if (existingMessage) {
        existingMessage.remove();
      }

      const p = document.createElement('div');
      p.classList.add('state-message', 'fw-semibold');

      switch (this.dataset.index) {
        case '0':
          p.textContent = 'Equipment is unsafe or non-functional. Do not operate.';
          break;
        case '1':
          p.textContent = 'Equipment is safe and functional. Use with caution.';
          break;
        default:
          p.textContent = '';
      }

      this.parentNode.parentNode.insertAdjacentElement('afterend', p);
    });
  });


  document.querySelectorAll('.btn-toggle-priority').forEach(button => {
    button.addEventListener('click', function(event) {
      event.preventDefault();
      document.querySelectorAll('.btn-toggle-priority').forEach(btn => btn.classList.remove('active'));

      const radioButton = document.getElementById(this.dataset.for);
      radioButton.checked = true;
      this.classList.add('active');

      const existingMessage = document.querySelector('.priority-message');
      if (existingMessage) {
        existingMessage.remove();
      }

      const p = document.createElement('div');
      p.classList.add('priority-message', 'fw-semibold');

      switch (this.dataset.index) {
        case '0':
          p.textContent = 'Routine issue; can be resolved in regular maintenance cycles.';
          break;
        case '1':
          p.textContent = 'Needs timely attention; impacts efficiency but not critical operations.';
          break;
        case '2':
          p.textContent = 'Immediate action required; essential for operations or safety.';
          break;
        default:
          p.textContent = '';
      }

      this.parentNode.parentNode.insertAdjacentElement('afterend', p);
    });
  });
});
