document?.addEventListener('turbolinks:load', function() {
  const statusDropdown = document.getElementById('status-dropdown');
  const priorityDropdown = document.getElementById('priority-dropdown');

  const ticketInfo = document.getElementById('ticket-info');
  const ticketId = ticketInfo.dataset.ticketId;

  function updateTicket(attribute, value) {
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    fetch(`/system_admin/tickets/${ticketId}`, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': token,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        ticket: {
          [attribute]: value
        }
      })
    })
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        console.log(`${attribute} updated successfully to ${value}`);
      } else {
        console.error('Update failed:', data.errors);
      }
    })
    .catch(error => console.error('Error:', error));
  }

  statusDropdown.addEventListener('change', (e) => {
    updateTicket('status', e.target.value);
  });

  priorityDropdown.addEventListener('change', (e) => {
    const priorityValue = e.target.value;  // This should give you the value of the selected option
    updateTicket('priority', priorityValue);
  });
});
