document.addEventListener('turbolinks:load', function() {
  // Listen for changes in the checkboxes with class 'active-checkbox'
  var specificPageElement = document.getElementById('preventative_tasks_select');
  if (specificPageElement) {
    $('.active-checkbox').change(function() {
      // Get the number of checked and not disabled checkboxes
      var checkedCount = $('.active-checkbox:checked:not(:disabled)').length;

      // Select the button you want to show/hide
      var button = $('.toggle-button');

      // Toggle the button's visibility based on the number of checked checkboxes
      if (checkedCount >= 2) {
        button.removeClass('hidden');
      } else {
        button.addClass('hidden');
      }
    });

    $('#complete-all-button').click(function() {
      var checkedTaskIds = [];

      // Collect the IDs of checked and not disabled checkboxes
      $('.active-checkbox:checked:not(:disabled)').each(function() {
        checkedTaskIds.push($(this).val());
      });

      // Get the data-action attribute to determine the server action URL
      var actionUrl = $(this).data('action');

      // Only proceed if there are checkboxes selected
      if (checkedTaskIds.length > 0) {
        // Send the checked task IDs to the server via AJAX
        $.ajax({
          url: actionUrl,
          type: 'POST',
          data: { task_ids: checkedTaskIds },
          success: function(response) {
            // Handle success response
          },
          error: function(error) {
            // Handle error response
          }
        });
      }
    });
  }
});
