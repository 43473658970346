$(document).on("turbolinks:load", function() {
  const buttons = document.querySelectorAll('.remove-part-doc-button');
  buttons.forEach(button => {
    button?.addEventListener('click', () => {
      const documentId = button.id;; // Replace with the actual part's ID
      const partId = button.getAttribute('data-part-id'); // Get part id
      fetch(`/parts/remove_part_documents`, { // Update the path to match your application
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({ document_id: documentId, part_id: partId})
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          window.location.href = `/parts/${data.part_id}/edit`; // Update with the correct path
        }
      });
    });
  });

  // Add an event listener to the category dropdown
  document?.getElementById('category-select')?.addEventListener('change', function() {
    const categoryId = this.value; // Get the selected category ID

    // Send an Ajax request to fetch subcategories based on the selected category
    $.ajax({
      url: '/parts/subcategories',
      method: 'GET',
      data: { category_id: categoryId },
      success: function(data) {
        // Update the subcategory dropdown with the fetched subcategories
        const subcategorySelect = document.getElementById('subcategory-select');
        subcategorySelect.innerHTML = ''; // Clear existing options

        // Add a prompt option
        const promptOption = document.createElement('option');
        promptOption.text = 'Select a Subcategory';
        subcategorySelect.appendChild(promptOption);

        // Add fetched subcategories as options
        data.forEach(function(subcategory) {
          const option = document.createElement('option');
          option.value = subcategory.id;
          option.text = subcategory.name;
          subcategorySelect.appendChild(option);
        });
      }
    });
  });

  $(document).on('change', '.supplier-checkbox', function() {
    if ($(this).prop('checked')) {
      // Uncheck other checkboxes with the same class.
      $('.supplier-checkbox').not(this).prop('checked', false);
    }
  });

  const addButton = document.getElementById('add-part-doc-button');
  addButton?.addEventListener('click', () => {
    const checkedItems = Array.from(document.querySelectorAll('.active-checkbox:checked'))
      .map(checkbox => checkbox.value);
      const supplierDataElement = document.getElementById('part-data');
      const partData = JSON.parse(supplierDataElement.getAttribute('data-part'));
      if (checkedItems.length > 0) {
        fetch('/parts/add_part_documents', { // Update with the correct path
          method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({ items: checkedItems, part: partData })
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          window.location.reload();
        }
      });
    }
  });
});
