import jstz from 'jstz';

$(document).on('turbolinks:load', function(){
  const timezone = jstz.determine();
  setCookie("timezone", timezone.name())
});

function setCookie(name, value) {
  var expires = new Date();
  expires.setTime(expires.getTime() + (24 * 60 * 60 * 1000));
  document.cookie = name + '=' + value + ';expires=' + expires.toUTCString();
}
