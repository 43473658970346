$(document).ready(function () {
  const confirmNo = document.getElementById("cross1");
  confirmNo?.addEventListener("click", closeConfirmationModal);
  function closeConfirmationModal() {
    $('#taskModal').modal('hide');
  }
  $('.btn.btn-link').click(function () {
    var taskName = $(this).data('task-name');
    var taskStatus = $(this).data('task-status');
    $('#task-modal-name').text(taskName);
    $('#task-modal-status').text(taskStatus);
    $('#taskModal').modal('show');
  });
});
