$(document).on("turbolinks:load", function() {
  const allButtons = document.querySelectorAll('.remove-docs-button');
  allButtons.forEach(button => {
    button?.addEventListener('click', () => {
      const documentId = button.id;; // Replace with the actual supplier's ID
      const itemId = button.getAttribute('data-item-id'); // Get item id
      fetch(`/items/remove_item_documents`, { // Update the path to match your application
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({ document_id: documentId, item_id: itemId})
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          window.location.href = `/items/${data.item_id}/edit`; // Update with the correct path
        }
      });
    });
  });

  const addButton = document.getElementById('add-item-doc-button');
  addButton?.addEventListener('click', () => {
    const checkedItems = Array.from(document.querySelectorAll('.active-checkbox:checked'))
      .map(checkbox => checkbox.value);
      const supplierDataElement = document.getElementById('item-data');
      const itemData = JSON.parse(supplierDataElement.getAttribute('data-item'));
      if (checkedItems.length > 0) {
        fetch('/items/add_item_documents', { // Update with the correct path
          method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({ items: checkedItems, item: itemData })
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          window.location.reload();          
        }
      });
    }
  });
});
