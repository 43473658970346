$(document).on("turbolinks:load", function() {
  const tabs = $(".document-tab");
  if (tabs.length > 0){
    tabs.on("click", function () {
      tabs.removeClass("selected");
      $(this).addClass("selected");
      const selectedTab = $(this).attr('document-data-tab');
      getContentForTab(selectedTab);
    });

    function getContentForTab(tab) {
      if (tab === "documents_files") {
        $('#documents_files_content').removeClass("d-none");
        $('#documents_links_content').addClass("d-none");
        $('#documents_history_content').addClass("d-none");
      } else if (tab === "documents_links") {
        $('#documents_files_content').addClass("d-none");
        $('#documents_links_content').removeClass("d-none");
        $('#documents_history_content').addClass("d-none");
      } else if (tab === "documents_history") {
        $('#documents_files_content').addClass("d-none");
        $('#documents_links_content').addClass("d-none");
        $('#documents_history_content').removeClass("d-none");
      }
      return "";
    }
    tabs.first().click();

    // Handle tab clicks
    $('.document-tab').click(function() {
      var tabId = $(this).attr('document-data-tab');
      selectTab(tabId);
    });

    // Check if there's a selected tab in local storage
    var storedTab = localStorage.getItem('documentSelectedTab');
    if (storedTab) {
      // Apply the "selected" class and show the content of the stored tab
      selectTab(storedTab);
    }
  }
});

// Function to handle tab selection
function selectTab(tabId) {
  // Remove the "selected" class from all tabs and hide all tab content
  $('.document-tab').removeClass('selected');
  $('.document-tab-content').addClass("d-none"); // Remove the "d-md-block" class from all tab contents
  // Add the "selected" class to the clicked tab and show its content
  $('[document-data-tab="' + tabId + '"]').addClass('selected');
  $('#' + tabId + '_content').removeClass("d-none");

  // Store the selected tab in local storage
  localStorage.setItem('documentSelectedTab', tabId);
}
