$(document).on("turbolinks:load", function() {
  const tabs = $(".team-tab");
  if (tabs.length > 0){
    tabs.on("click", function () {
      tabs.removeClass("selected");
      $(this).addClass("selected");

      const selectedTab = $(this).attr('team-data-tab');
      getContentForTab(selectedTab);
    });

    function getContentForTab(tab) {
      if (tab === "team_members") {
        $('#team_members_content').removeClass("d-none");
        $('#team_requests_content').addClass("d-none");
        $('#team_maintenances_content').addClass("d-none");
        getFieldsForTab(tabs)
      } else if (tab === "team_requests") {
        $('#team_members_content').addClass("d-none");
        $('#team_requests_content').removeClass("d-none");
        $('#team_maintenances_content').addClass("d-none");
      } else if (tab === "team_maintenances") {
        $('#team_members_content').addClass("d-none");
        $('#team_requests_content').addClass("d-none");
        $('#team_maintenances_content').removeClass("d-none");
      }
      return "";
    }

    tabs.first().click();
    // Handle tab clicks
    $('.team-tab').click(function() {
      var tabId = $(this).attr('team-data-tab');
      selectTab(tabId);
    });

    // Check if there's a selected tab in local storage
    var storedTab = localStorage.getItem('teamSelectedTab');
    if (storedTab) {
      // Apply the "selected" class and show the content of the stored tab
      selectTab(storedTab);
    }
  }
});

function getFieldsForTab(tabs) {
  let selectedTab = null;
  tabs.each(function() {
    if ($(this).hasClass("selected")) {
      selectedTab = this; // Store the selected tab DOM element
    }
  });

  tab_name = getSelectedTabName(selectedTab)

  if (selectedTab.getAttribute("team-data-tab") === "team_members") {
    tab_name = "team_members"
  }
};

function getSelectedTabName(selectedTab){
  if (selectedTab.getAttribute("team-data-tab") === "team_members") {
    return "team_members"
  }
}

// Function to handle tab selection
function selectTab(tabId) {
  // Remove the "selected" class from all tabs and hide all tab content
  $('.team-tab').removeClass('selected');
  $('.team-tab-content').addClass("d-none"); // Remove the "d-md-block" class from all tab contents
  // Add the "selected" class to the clicked tab and show its content
  $('[team-data-tab="' + tabId + '"]').addClass('selected');
  $('#' + tabId + '_content').removeClass("d-none");

  // Store the selected tab in local storage
  localStorage.setItem('teamSelectedTab', tabId);
}
