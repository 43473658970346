$(document).on("turbolinks:load", function() {
  const allButtons = document.querySelectorAll('.remove-documents-button');
  allButtons.forEach(button => {
    button?.addEventListener('click', () => {
      const documentId = button.id;
      const documentableId = button.getAttribute('data-document-id1');
      fetch(`/documents/remove_documents`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({ document_id: documentId, documentable_id: documentableId})
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          window.location.href = `/documents/${data.document_id}`;
        }
      });
    });
  });
})
