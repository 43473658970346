// import all files here under shared
import './select2_setup'
import './dropdown'
import './suppliers_tabs'
import './items_tabs'
import './parts_tabs'
import './documents_tabs'
import './suppliers_script'
import './parts_script'
import './items_script'
import './documents_script'
import './request_popup'
import './item_modal_script'
import './task_modal_script'
import './search_dropdown'
import './tasks_select_all'
import './sidebar_script'
import './teams_tabs'
import './tickets'
import './safety_concerns'
import './maintenance'
// TODO: We need to fix in future to load domain specific js to its module.
// import './action_button'
