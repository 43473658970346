import consumer from "./consumer"

consumer.subscriptions.create("NotificationChannel", {
  connected() {
    console.log('Notificatin Channel Connected.');
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    var notification_container = document.getElementById(`notification_${data.user.id}`)
    if (notification_container){
    var parser = new DOMParser();
    notification_container.prepend(parser.parseFromString(data.notification, 'text/html').getElementsByClassName('test')[0])
    notification_container.removeChild(notification_container.lastElementChild);
    notificationDot = document.querySelector(".notification-container > span")
    notificationDot.classList.add('position-absolute', 'top-7', 'start-80', 'translate-middle', 'p-1', 'bg-danger', 'border', 'border-light', 'rounded-circle')    }
    // Called when there's incoming data on the websocket for this channel
  }
});
