$(document).on("turbolinks:load", function() {
  // Automatically expand parent menu when submenu is active
  $('.nav-item .nav-link').on('click', function() {
    $(this).closest('.nav-item').addClass('active').siblings().removeClass('active');
  });

  // Function to open dropdown on click
  $('.dropbtn').on('click', function (event) {
    var dropdownMenu = $(this).next('.dropdown-content');
    var buttonWidth = $(this).outerWidth();
    var buttonPosition = $(this).position();
    dropdownMenu.css({
      right: 'auto',
      left: buttonPosition.left + buttonWidth + 'px'
    });

    // Show the clicked dropdown
    dropdownMenu.addClass('show');

    // Hide other dropdowns not related to the clicked menu
    var relatedDropdowns = $(this).closest('.dropdown').siblings('.dropdown').find('.dropdown-content');
    relatedDropdowns.removeClass('show');

    event.stopPropagation(); // Prevent closing due to document click
  });

  // Function to close dropdowns when clicking outside
  $(document).on("click", function (event) {
    if (!$(event.target).closest('.dropdown-content').length) {
      $('.dropdown-content').removeClass('show');
    }
  });

  // Function to close dropdowns when hovering outside
  $('.dropdown-content').on("mouseleave", function() {
    $(this).removeClass('show');
  });
});
