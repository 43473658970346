// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require jquery
//= require jquery_ujs

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "./shared/index"
import "./user"
import "bootstrap"
import 'cocoon-js';
import "trix"
import "@rails/actiontext"

import 'daterangepicker';
import 'daterangepicker/daterangepicker.css';
import $ from 'jquery';
import 'bootstrap-datepicker';

//= require suppliers/contacts
// // Make jQuery available everywhere
// global.$ = require('jquery')

Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(function() {
  $("#table th a, #table .pagination a").on("click", function() {
    $.getScript(this.href);
    return false;
  });
});

import "controllers"

require("trix")
require("@rails/actiontext")

document.addEventListener('turbolinks:load', () => {
  // Initialize the date range picker
  $('.date-range-picker').daterangepicker({
    opens: 'left', // Adjust the position as needed
  });

  // Listen for the apply event and trigger the AJAX request
  $('.date-range-picker').on('apply.daterangepicker', function(ev, picker) {
    // Retrieve the selected start and end dates
    var start = picker.startDate.format('YYYY-MM-DD');
    var end = picker.endDate.format('YYYY-MM-DD');
    var filterType = $(this).data('filter-type');

    // Prepare the data to be sent to the controller action
    var data = {
      start_date: start,
      end_date: end,
      filter_type: filterType
    };
    var currentPageUrl = window.location.pathname + '.js'; // Append .js for JavaScript format

    // Send the data via an AJAX GET request to the preventative_maintenance action
    $.ajax({
      url: currentPageUrl, // Use the current page URL
      method: 'GET',
      data: data,
      success: function(response) {
        // Handle the success response from the server
        console.log('Data sent successfully:');
      },
      error: function(xhr, status, error) {
        // Handle errors if any
        console.error('Error sending data:', error);
      }
    });
  });

  // Handler to show the import form
  document.getElementById('show-import-form').addEventListener('click', function() {
    var importFormContainer = document.getElementById('import-form-container');
    var exportButtonsContainer = document.getElementById('export-buttons-container');

    if (importFormContainer.style.display === 'none') {
      importFormContainer.style.display = 'block';
      exportButtonsContainer.style.display = 'none';
    } else {
      importFormContainer.style.display = 'none';
    }
  });
});
