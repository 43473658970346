import { Controller } from "stimulus"
import Tribute from "tributejs"
import Trix from "trix"

export default class extends Controller {
  static targets = [ "field" ]

  connect() {
    this.editor = this.fieldTarget.editor
    this.initializeTribute()
  }

  disconnect() {
    this.tribute.detach(this.fieldTarget)
  }

  initializeTribute(){
    this.tribute = new Tribute({
      allowSpaces: true,
      lookup: 'username',
      values: this.fetchUsers,
    })
    this.tribute.attach(this.fieldTarget)
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
    this.fieldTarget?.addEventListener("tribute-replaced", this.replaced.bind(this))
  }

  fetchUsers(text, callback){
    fetch(`/mentions.json?query=${text}`)
    .then(response => response.json())
    .then(users => callback(users))
    .catch(error => callback([]))
  }

  replaced(e) {
    let mention = e.detail.item.original;
    let attachment = new Trix.Attachment({
      sgid: mention.sgid,
      content: mention.content
    });

    let currentRange = this.editor.getSelectedRange();
    let cursorPosition = currentRange[0];
    let documentText = this.editor.getDocument().toString();
    let textUpToCursor = documentText.substring(0, cursorPosition);

    // Regex to find an "@" that is not followed by a space, up to the cursor position
    let mentionRegex = /@[^@\s]*$/;
    let match = textUpToCursor.match(mentionRegex);

    if (match) {
      let mentionStart = cursorPosition - match[0].length;
      this.editor.setSelectedRange([mentionStart, cursorPosition]);
      this.editor.deleteInDirection("backward");
    }

    this.editor.insertAttachment(attachment);
    this.editor.insertString(" ");
  }

  _pasteHtml(html, startPos, endPos){
    let position = this.editor.getPosition()
    this.editor.setSelectedRange([endPos, position])
    this.editor.deleteInDirection("backward")
  }
}
