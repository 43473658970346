document?.addEventListener('turbolinks:load', function() {
  const completeTaskBtn = $("#complete-task-btn");
  const confirmYes = $("#confirm-yes");
  const confirmNo = $("#confirm-no");
  const confirmNoButton = $("#confirm-no-button");

  confirmNo.on("click", closeConfirmationModal);
  confirmNoButton.on("click", closeConfirmationModal);
  completeTaskBtn.on("click", function() {
    $('#confirmationModal').modal('show');
  });

  function closeConfirmationModal() {
    $('#confirmationModal').modal('hide');
  }

  confirmYes.on("click", function() {
    $("form").submit();
  });
});
