$(document).on("turbolinks:load", function() {
  $('#ajax-button').click(function() {
    // Make the AJAX request
    $.ajax({
      url: '/your_ajax_endpoint',  // Replace with the actual URL
      method: 'GET',              // Use GET, POST, or other HTTP methods as needed
      dataType: 'json',           // Specify the expected data type

      success: function(response) {
        // Handle the success response
        $('#ajax-result').html('AJAX response: ' + response.message);
      },

      error: function(xhr, status, error) {
        // Handle the error
        console.error('AJAX request failed:', error);
      }
    });
  });

  const buttons = document.querySelectorAll('.remove-supplier-document-button');
  buttons.forEach(button => {
    button?.addEventListener('click', () => {
      const documentId = button.id;; // Replace with the actual supplier's ID
      const supplierId = button.getAttribute('data-supplier-id'); // Get supplier id
      fetch(`/suppliers/remove_supplier_documents`, { // Update the path to match your application
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({ document_id: documentId, supplier_id: supplierId})
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          window.location.href = `/suppliers/${data.supplier_id}/edit`; // Update with the correct path
        }
      });
    });
  });

  const addButton = document.getElementById('add-supplier-doc-button');
  addButton?.addEventListener('click', () => {
    const checkedItems = Array.from(document.querySelectorAll('.active-checkbox:checked'))
      .map(checkbox => checkbox.value);
      const supplierDataElement = document.getElementById('supplier-data');
      const supplierData = JSON.parse(supplierDataElement.getAttribute('data-supplier'));
      if (checkedItems.length > 0) {
        fetch('/suppliers/add_supplier_documents', { // Update with the correct path
          method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({ items: checkedItems, supplier: supplierData })
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          window.location.reload();
        }
      });
    }
  });
});

document.addEventListener('turbolinks:load', () => {
  document.addEventListener('change', function(event) {
    // Check if the change event is coming from a .part-selector
    if (event.target.matches('.part-selector')) {
      const selector = event.target;
      const partId = selector.value;
      const url = `${selector.dataset.partsDetailsUrl}?part_id=${partId}`;

      fetch(url)
        .then(response => response.json())
        .then(data => {
          if(data) {
            selector.closest('.nested-fields').querySelector('[name$="[description]"]').value = data.description || '';
            selector.closest('.nested-fields').querySelector('[name$="[u_of_m]"]').value = data.u_of_m || '';
          }
        })
        .catch(error => console.error('Error:', error));
    }
  });

  const toggleButton = document.getElementById('show-export-buttons');
  const exportButtonsContainer = document.getElementById('export-buttons-container');
  var importFormContainer = document.getElementById('import-form-container');

  if (toggleButton) {
    toggleButton.addEventListener('click', function () {
      if (exportButtonsContainer.style.display === 'none') {
        exportButtonsContainer.style.display = 'block';
        importFormContainer.style.display = 'none';
      } else {
        exportButtonsContainer.style.display = 'none';
      }
    });
  }

});
