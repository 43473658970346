$(document).on("turbolinks:load", function() {
  const tabs = $(".supplier-tab");
  if (tabs.length > 0){
    tabs.on("click", function () {
      tabs.removeClass("selected");
      $(this).addClass("selected");

      const selectedTab = $(this).attr('supplier-data-tab');
      getContentForTab(selectedTab);
    });

    function getContentForTab(tab) {
      if (tab === "supplier_details") {
        $('#supplier_details_content').removeClass("d-none");
        $('#supplier_contacts_content').addClass("d-none");
        $('#supplier_documentations_content').addClass("d-none");
        $('#supplier_parts_content').addClass("d-none");
        $('#supplier_history_content').addClass("d-none");
        getFieldsForTab(tabs)
      } else if (tab === "supplier_contacts") {
        $('#supplier_details_content').addClass("d-none");
        $('#supplier_contacts_content').removeClass("d-none");
        $('#supplier_documentations_content').addClass("d-none");
        $('#supplier_parts_content').addClass("d-none");
        $('#supplier_history_content').addClass("d-none");
      } else if (tab === "supplier_documentations") {
        $('#supplier_details_content').addClass("d-none");
        $('#supplier_contacts_content').addClass("d-none");
        $('#supplier_documentations_content').removeClass("d-none");
        $('#supplier_parts_content').addClass("d-none");
        $('#supplier_history_content').addClass("d-none");
      } else if (tab === "supplier_parts") {
        $('#supplier_details_content').addClass("d-none");
        $('#supplier_contacts_content').addClass("d-none");
        $('#supplier_documentations_content').addClass("d-none");
        $('#supplier_parts_content').removeClass("d-none");
        $('#supplier_history_content').addClass("d-none");
      } else if (tab === "supplier_history") {
        $('#supplier_details_content').addClass("d-none");
        $('#supplier_contacts_content').addClass("d-none");
        $('#supplier_documentations_content').addClass("d-none");
        $('#supplier_parts_content').addClass("d-none");
        $('#supplier_history_content').removeClass("d-none");
      }
      return "";
    }

    tabs.first().click();
    // Handle tab clicks
    $('.supplier-tab').click(function() {
      var tabId = $(this).attr('supplier-data-tab');
      selectTab(tabId);
    });

    // Check if there's a selected tab in local storage
    var storedTab = localStorage.getItem('supplierSelectedTab');
    if (storedTab) {
      // Apply the "selected" class and show the content of the stored tab
      selectTab(storedTab);
    }
  }
});

function getFieldsForTab(tabs) {
  let selectedTab = null;
  tabs.each(function() {
    if ($(this).hasClass("selected")) {
      selectedTab = this; // Store the selected tab DOM element
    }
  });

  tab_name = getSelectedTabName(selectedTab)

  if (selectedTab.getAttribute("supplier-data-tab") === "supplier_details") {
    tab_name = "extra_details"
  }

  const pairsContainer = document.getElementById(`${tab_name}-key-value-pairs`);
  const addPairButton = document.getElementById(`${tab_name}-add-pair`);
  const pattern = /^\/suppliers\/(new|\d+\/edit)$/;
  // Get the current pathname
  const currentPathname = window.location.pathname;

  // Test if the current pathname matches the pattern
  const isMatch = pattern.test(currentPathname);

  if (isMatch) {
    addPairButton?.addEventListener('click', function() {
      const newKeyInput = document.querySelector(`.${tab_name}-key-value-pair input[name="supplier[new_key]"]`);
      const newValueInput = document.querySelector(`.${tab_name}-key-value-pair input[name="supplier[new_value]"]`);

      const newKey = newKeyInput.value;
      const newValue = newValueInput.value;

      if (!newKey || !newValue) return;

      const newPair = `<div class="${tab_name}-key-value-pair d-flex gap-2 align-items-center mt-3">
      <div class="col-3"><input type="text" name="supplier[${tab_name}][${newKey}]" value="${newKey}" placeholder="Value" class="form-control d-flex" ></div>
      <div class="col-3"><input type="text" name="supplier[${tab_name}][${newKey}]" value="${newValue}" placeholder="Value" class="form-control d-flex"></div>
      <div class="col-3">
        <button type="button" class="${tab_name}-remove-pair border-0 bg-transparent">
          <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.20605 6.9707H6.38253H15.7943" stroke="black" stroke-width="0.882353" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.14752 6.97062V5.79415C8.14752 5.48213 8.27147 5.18289 8.4921 4.96226C8.71273 4.74162 9.01197 4.61768 9.32399 4.61768H11.6769C11.9889 4.61768 12.2882 4.74162 12.5088 4.96226C12.7295 5.18289 12.8534 5.48213 12.8534 5.79415V6.97062M14.6181 6.97062V15.2059C14.6181 15.5179 14.4942 15.8172 14.2735 16.0378C14.0529 16.2584 13.7537 16.3824 13.4416 16.3824H7.55928C7.24726 16.3824 6.94802 16.2584 6.72739 16.0378C6.50676 15.8172 6.38281 15.5179 6.38281 15.2059V6.97062H14.6181Z" stroke="black" stroke-width="0.882353" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.32422 9.91211V13.4415" stroke="black" stroke-width="0.882353" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.6768 9.91211V13.4415" stroke="black" stroke-width="0.882353" stroke-linecap="round" stroke-linejoin="round"/>
            <circle cx="10.5" cy="10.5" r="9.41177" stroke="black" stroke-width="1.17647"/>
          </svg>
        </button>
      </div>
      </div>`;

      pairsContainer.insertAdjacentHTML('beforeend', newPair);

      newKeyInput.value = '';
      newValueInput.value = '';
    });
    pairsContainer?.addEventListener('click', function(event) {
      const closestElement = event.target.closest(`.${tab_name}-key-value-pair`);
      if (closestElement) {
        if (event.target.parentElement.classList.contains(`${tab_name}-remove-pair`)) {
          closestElement.remove();
        }
      }
    });
  }
};

function getSelectedTabName(selectedTab){
  if (selectedTab.getAttribute("supplier-data-tab") === "supplier_details") {
    return "extra_details"
  }
}

// Function to handle tab selection
function selectTab(tabId) {
  // Remove the "selected" class from all tabs and hide all tab content
  $('.supplier-tab').removeClass('selected');
  $('.supplier-tab-content').addClass("d-none"); // Remove the "d-md-block" class from all tab contents
  // Add the "selected" class to the clicked tab and show its content
  $('[supplier-data-tab="' + tabId + '"]').addClass('selected');
  $('#' + tabId + '_content').removeClass("d-none");

  // Store the selected tab in local storage
  localStorage.setItem('supplierSelectedTab', tabId);
}
