document.addEventListener('DOMContentLoaded', (event) => {
  // Listen for clicks on the document
  document.addEventListener('click', function(event) {
    var mainHeader = document.getElementById('mainHeader');

    var searchDropdown = document.getElementById('searchDropdown');
    var searchResults = document.getElementById('searchResults');

    if (searchDropdown && searchResults) {
      var isClickInsideDropdown = searchDropdown.contains(event.target);

      if (!isClickInsideDropdown) {
        searchResults.innerHTML = ''; // Clear search results
      }
    }
  });
});
