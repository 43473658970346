document?.addEventListener('turbolinks:load', function() {

  $('.sort-link').click(function(event) {
    event.preventDefault();
    $link = $(this);
    sort_order = $link.data('sort-order');
    sort_column = $link.data('sort-column');
    new_sort_order = sort_order === 'asc' ? 'desc' : 'asc';
    // Get the existing filter values from other dropdowns
    const existingFilters = [];
    $('.user-select').each(function() {
      const $select = $(this);
      const filterType = $select.data('filterType');
      const selectedValues = $select.val();
      if (selectedValues && selectedValues.length > 0) {
        existingFilters.push({ selected_filters: selectedValues, filter_type: filterType });
      }
    });
    url_filtered_values = ''
    if(window.location.search.substring(1)){
      url_filtered_values = parseQueryParams();
    }
    // Construct the selected_filters part of the query
    let selected_filters = [];
    if (existingFilters.length > 0) {
      selected_filters = existingFilters;
    }
    if (url_filtered_values)
    {
      selected_filters.push(url_filtered_values)
    }
    // Construct the sort part of the query
    let sort_query = '';
    if (sort_column && new_sort_order) {
      sort_query = {sort_column: sort_column, sort_order: new_sort_order };
    }

    // Combine the filter and sort parts of the query
    if (selected_filters && sort_query) {
      selected_filters.push(sort_query);
    } else if (!selected_filters && sort_query) {
      selected_filters = sort_query;
    }

    // Update the sort link with the new sort values
    const queryParams = selected_filters ;
    const csrfToken = $('meta[name="csrf-token"]').attr('content');
    var element = document.querySelector('.tab.select-tab-data-load.selected');
    var idValue = element ? element?.id : null; // This will store the 'id' value of the element or null if the element doesn't exist

    $link.data('sort-order', new_sort_order);
    $.ajax({
      type: 'GET',
      url: $link.attr('href'),
      data: {
        data: JSON.stringify(queryParams),
        tab: idValue
      }, // Convert queryParams to JSON string
      dataType: "script",
      traditional: true, // Ensure that the array data is serialized correctly
      headers: {
        'X-CSRF-Token': csrfToken // Include the CSRF token in the request headers
      }
    });
  });

  function parseQueryParams() {
    const query = window.location.search.substring(1);
    const queryParams = {};
    const params = query.split('&');
    params.forEach((param) => {
      const [key, value] = param.split('=');
      if (key !== 'commit') {
        const decodedKey = decodeURIComponent(key);
        const decodedValue = decodeURIComponent(value.replace(/\+/g, ' '));
        if (decodedKey.endsWith('[]')) {
          const arrayKey = decodedKey.slice(0, -2);
          if (!queryParams[arrayKey]) {
            queryParams[arrayKey] = [];
          }
          queryParams[arrayKey].push(decodedValue);
        } else {
          queryParams[decodedKey] = decodedValue;
        }
      }
    });
    return queryParams;
  }
});

function cleanupAllDropdownsExceptFirst() {
  var dropdowns = document.querySelectorAll('.select2-container');

  for (var i = 0; i < dropdowns.length; i++) {
    dropdowns[i].remove();
  }
}

document?.addEventListener('turbolinks:load', function() {
  cleanupAllDropdownsExceptFirst();
})
