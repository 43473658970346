import $ from 'jquery'
import 'select2'

window?.addEventListener('turbolinks:load', () => {
  $('.select2').select2({
    tags: true,
    tokenSeparators: [',', ' '],
    createTag: function () {
      // Disable tagging
      return null;
    }
  });

  function initializeSelect2($select) {
    const dropdownId = $select.data('dropdownId');
    $(`#${dropdownId}`).select2({
      tags: true,
      tokenSeparators: [',', ' '],
      createTag: function () {
        // Disable tagging
        return null;
      }
    });
  }

  // Initialize existing dropdowns with class 'user-select'
  $('.user-select').each(function () {
    initializeSelect2($(this));
  });

  $(document).on('change', '.user-select', function() {
    const selectedData = [];
    $('.user-select').each(function() {
      const $select = $(this);
      const selectedValues = $select.val();
      const filterType = $select.data('filterType');
      const parentFilterType = $select.data('parentFilterType');
      if (selectedValues && selectedValues.length > 0) {
        $(`#${filterType}_filter_icon`).removeClass('d-none');
        selectedData.push({ selected_filters: selectedValues, filter_type: filterType, parent_filter_type: parentFilterType });
      }
      else{
        $(`#${filterType}_filter_icon`).addClass('d-none');
      }
    });

    // Get the current URL without query parameters
    const currentUrlWithoutParams = window.location.origin + window.location.pathname;

    // Clean the query parameters from the URL
    const urlWithoutParams = new URL(currentUrlWithoutParams);
    urlWithoutParams.search = '';
    var element = document.querySelector('.tab.select-tab-data-load.selected');
    var idValue = element ? element?.id : null; // This will store the 'id' value of the element or null if the element doesn't exist
    // Get the CSRF token from the meta tag
    const csrfToken = $('meta[name="csrf-token"]').attr('content');
    $.ajax({
      url: urlWithoutParams.href,
      method: "GET",
      data: {
        data: JSON.stringify(selectedData),
        tab: idValue
      }, // Convert selectedData to JSON string
      dataType: "script",
      traditional: true, // Ensure that the array data is serialized correctly
      headers: {
        'X-CSRF-Token': csrfToken // Include the CSRF token in the request headers
      }
    });
    // Update the URL without reloading the page
    history.pushState(null, '', urlWithoutParams.href);
  });
})

// For Select All
window?.addEventListener('turbolinks:load', () => {
  $(".js-check-all").click(function(){
    if($(".js-check-all").is(':checked')) {
      $(".select2 > option").prop("selected","selected");
    } else {
      $(".select2 > option").removeAttr("selected");
    }
    $(".select2").trigger("change");
  });

  $(".js-check-all-users").click(function() {
    if ($(this).is(':checked')) {
        // Select all options
        $(".select-task-users > option").prop("selected", true);
    } else {
        // Deselect all options
        $(".select-task-users > option").prop("selected", false);
    }
    // Trigger the change event on the select element to update the UI
    $(".select-task-users").trigger("change");
  });

});
