$(document).on('click', '.btn.request-trigger', function() {
  var button = $(this); // The clicked button
  var dataIdValue = button.data('id');

  // Make an AJAX request using jQuery
  $.ajax({
    url: '/requests/' + dataIdValue + '/show_modal.js',
    dataType: 'html',
    success: function(data) {
      var modal = $('#requestShowModal .modal-body');
      modal.html(data);

      // Apply the CSS classes
      modal.addClass('scrollable-modal-body');

      // Show the modal
      $('#requestShowModal').modal('show');

      var modelHeader = $('#requestShowModal .modal-header');
      modelHeader.addClass('sticky-modal-header');

      var identifierValue = button.data('identifier');
      modelHeader.children().first().text(identifierValue);
    }
  })
  .catch(error => {
    console.error('Error fetching request for modal:', error);
  });
});
