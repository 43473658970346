document?.addEventListener('turbolinks:load', function() {
  document.querySelectorAll('.btn-toggle').forEach(button => {
    button.addEventListener('click', function() {
      // Remove active class from all buttons
      document.querySelectorAll('.btn-toggle').forEach(btn => btn.classList.remove('active'));

      // Mark the current button as active
      const radioButton = document.getElementById(this.getAttribute('for'));
      if (this.dataset.index != "0") {
        document.getElementById('js-concerns-block').classList.add('d-none');
      } else {
        document.getElementById('js-concerns-block').classList.remove('d-none');
      }

      radioButton.checked = true;
      this.classList.add('active');

      // Remove the previous <p> tag if exists
      const existingMessage = document.querySelector('.condition-message');
      if (existingMessage) {
        existingMessage.remove();
      }

      // Create a new <p> tag with the corresponding message
      const p = document.createElement('div');
      p.classList.add('condition-message','fw-semibold');

      // Check the data-index value and add the appropriate message
      switch (this.dataset.index) {
        case '0':
          p.textContent = 'An event that could have resulted in injury';
          break;
        case '1':
          p.textContent = 'A suggestion to improve safety';
          break;
        case '2':
          p.textContent = 'An unsafe condition that could result in injury';
          break;
        default:
          p.textContent = ''; // Optional: in case of other indexes
      }

      this.parentNode.parentNode.insertAdjacentElement('afterend', p);
    });
  });

  document.querySelectorAll('.btn-toggle-concern').forEach(button => {
    button.addEventListener('click', function() {
      // Remove active class from all buttons
      document.querySelectorAll('.btn-toggle-concern').forEach(btn => btn.classList.remove('active'));

      // Mark the current button as active and check the corresponding radio button
      const radioButton = document.getElementById(this.getAttribute('for'));
      radioButton.checked = true;
      this.classList.add('active');

      // Remove the previous <p> tag if exists
      const existingMessage = document.querySelector('.concern-message');
      if (existingMessage) {
        existingMessage.remove();
      }

      // Create a new <p> tag with the corresponding message
      const p = document.createElement('div');
      p.classList.add('concern-message', 'fw-semibold');

      // Check the data-index value and add the appropriate message
      switch (this.dataset.index) {
        case '0':
          p.textContent = 'Could resulted in a minor injury with no long lasting impact.';
          break;
        case '1':
          p.textContent = 'Could resulted in a moderate non-life threatening injury, requiring medical attention.';
          break;
        case '2':
          p.textContent = 'Could result in a serious or life threatening injury with major health impact';
          break;
        // Add more cases as needed for other concerns
        default:
          p.textContent = '';
      }

      // Insert the message after the button group
      this.parentNode.parentNode.insertAdjacentElement('afterend', p);
    });
  });
});
